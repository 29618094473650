<template>
<v-container>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6>
            <v-card>
                <v-card-title>
                    <h1 class="headline">Вход</h1>

                </v-card-title>
                <v-progress-linear color="green" indeterminate v-show="loading"></v-progress-linear>
                <v-card-text>
                    <v-form ref="form">
                        <v-layout justify-center align-center v-show="error" v-text="error">

                        </v-layout>

                        <v-text-field v-model="account.email"
                                        prepend-icon="mdi-account"
                                        label="E-mail"></v-text-field>
                        <v-text-field v-model="account.password"
                                        type="password"
                                        prepend-icon="mdi-lock"
                                        label="Пароль"></v-text-field>
                        <v-layout>
                            <v-btn @click="login" color="primary">
                                Войти
                            </v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

</v-container>
</template>

<script>
export default {
   name: 'Login',
   data() {
        return {
            error: '',
            loading: false,
            account: {
                email: '',
                password: ''
            },
            logText: "",
        }
    },
    methods: {
        login: function (token) {
           this.error = '';
           this.loading = false;
           this.$auth.login({
               data: { username: this.account.email, password: this.account.password },
               rememberMe: true,
               redirect: '/dashboard',
               fetchUser: true
           })
               .then(() => {
                   //this.emitLogin();
                   //this.loginDialog = false;
               }, (error) => {
                   this.error = error.response.data.error;
               });
        }
    }
}
</script>
